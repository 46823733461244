body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f6f6f7;
  color: #202223;
  font-family: 'Roboto', sans-serif;
}

#root {
  max-width: 1000px;
  margin: 0 auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1 {
  font-size: 2.2rem;
}

main {
  margin: 0 25px 25px;
}

section {
  border-radius: 10px;
  background: white;
  margin-bottom: 35px;
  padding: 25px;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
  position: sticky;
  top: 0;
  background: #f6f6f7;
  z-index: 1;
}

.button {
  display: block;
  border-radius: 5px;
  padding: 8px 10px;
  color: white;
  cursor: pointer;
  background-color: #008060;
  text-decoration: none;
  min-width: 68px;
  text-align: center;
}

.button:hover {
  background-color: #006e52;
}

.cancel-link {
  color: #d82c0d;
  border-bottom: 1px solid #d82c0d;
  text-decoration: none;
}

.cancel-link:hover {
  color: #bc2200;
}

button.disabled {
  color: #545454;
  background: #c7c7c7;
  pointer-events: none;
}

button.loading {
  position: relative;
  pointer-events: none;
}

button.loading::after {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  color: #545454;
  background: #c7c7c7;
  pointer-events: none;
  background-size: contain;
  content: 'Saving';
  line-height: 2;
}

.loading-div {
  pointer-events: none;
  opacity: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: 100ms ease;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.5);
  z-index: 10;
}

.loading-div.loading {
  pointer-events: all;
  opacity: 1;
}

fieldset {
  border: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  padding-bottom: 25px;
  border-bottom: 1px solid #e1e3e5;
  margin: 0 auto 25px;
  align-items: center;
}

section fieldset:last-of-type {
  margin-bottom: 15px;
}

input,
select {
  caret-color: #202223;
  padding: 0.5rem 1.2rem;
  border: 1px solid #c9cccf;
  border-top-color: #aeb4b9;
  border-radius: 0.4rem;
}

fieldset label {
  width: 20%;
  padding-right: 2%;
  font-weight: 600;
}

fieldset input,
fieldset select {
  width: 80%;
}

.help-text {
  margin-top: 20px;
  margin-bottom: 0;
  color: #6d7175;
  width: 100%;
}

.products-list-container,
.collection-list-container {
  height: 500px;
  overflow-y: scroll;
}

.products-list-container svg,
.collection-list-container svg {
  margin: 50px auto !important;
}

.products-list .help-text,
.collection-list .help-text {
  margin-top: 0px;
}

input[type='search'] {
  margin-top: 12px;
  margin-bottom: 12px;
  width: 100%;
}

/* clears the ‘X’ from Internet Explorer */
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* clears the ‘X’ from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

.product-list-item,
.collection-list-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e1e3e5;
  padding: 10px;
  cursor: pointer;
  position: relative;
}

.product-list-item.in-deal {
  opacity: 0.5;
  pointer-events: none;
}

.product-list-item:hover,
.collection-list-item:hover {
  background: whitesmoke;
}

.product-list-item img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-right: 30px;
}

.product-list-item p,
.collection-list-item p {
  margin: 0;
}

.product-price {
  font-size: 13px;
  color: #626262;
}

.product-tags-container {
  display: flex;
  flex-wrap: wrap;
}

.product-tags-container span {
  display: block;
  background: #dedede;
  color: #5f5f5f;
  font-size: 13px;
  border-radius: 7px;
  padding: 3px;
  margin-right: 7px;
  margin-bottom: 2px;
}

.product-list-item svg {
  position: absolute;
  left: 38px;
  top: -10px;
  fill: #007f60;
  display: none;
}

.collection-list-item svg {
  position: absolute;
  right: 20px;
  fill: #007f60;
  display: none;
}

.product-list-item.adding svg {
  fill: #007f60;
}

.product-list-item.removing svg {
  fill: #d82c0d;
}

.product-list-item:hover img {
  opacity: 0.2;
}

.product-list-item:hover svg,
.collection-list-item:hover svg {
  display: block;
}

.tabs {
  width: calc(100% + 50px);
  display: flex;
  justify-content: space-between;
  margin-top: -25px;
  margin-left: -25px;
  margin-right: -25px;
  border-bottom: 1px solid #e1e3e5;
  margin-bottom: 20px;
}

.tabs span {
  flex: 1;
  width: 50%;
  text-align: center;
  padding: 10px;
  color: #6d7175;
  border-bottom: 3px solid white;
  cursor: pointer;
}

.tabs span:hover {
  border-color: #cccccc;
}

.tabs span.active {
  color: #202223;
  border-color: #008060;
}

.multibuy-tags {
  display: flex;
  flex-wrap: wrap;
}

.multibuy-tags span {
  background: #008060;
  border-radius: 50px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 4px 11px;
  color: white;
  font-size: 14px;
}

.deal-table-child {
  display: flex;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #e1e3e5;
}

.deal-table-child:last-of-type {
  border: none;
}

.deal-table-header {
  padding-top: 0;
}

.deal-table-header div {
  font-weight: bold;
}

.deal-table-child .deal-table-name {
  width: 28%;
}

.deal-table-child .deal-table-type {
  width: 20%;
}

.deal-table-child .deal-table-description {
  width: calc(32% - 15px);
  padding-right: 15px;
}

.deal-table-child .deal-table-controls {
  width: 21%;
}

.deal-table-controls {
  display: flex;
  justify-content: space-between;
}

.deal-table-controls .cancel-link {
  align-self: center;
  cursor: pointer;
}

.spend-discounts-pair {
  display: flex;
  border-bottom: 1px solid #e1e3e5;
  margin-bottom: 25px;
}

.spend-discounts-pair fieldset {
  margin: 0;
  width: 50%;
  flex-direction: column;
  align-items: flex-start;
  border: none;
  padding-bottom: 10px;
}

.spend-discounts-pair fieldset label,
.spend-discounts-pair fieldset input {
  width: 100%;
}

.spend-discounts-pair fieldset:first-of-type {
  padding-right: 20px;
}

@media screen and (min-width: 1000px) {
  section {
    padding: 25px 34px;
  }
  .two-columns {
    display: flex;
  }

  .two-columns section {
    width: calc(50% - 17.5px);
  }

  .two-columns section:first-of-type {
    margin-right: 35px;
  }

  .two-columns section:first-of-type .products-list-container {
    height: 560px;
  }
  .tabs {
    width: calc(100% + 68px);
    margin-left: -34px;
    margin-right: -34px;
  }
}
